<template>
  <div>
    <Main/>
  </div>
</template>

<script>

import Main from '../components/Main.vue'

  export default {
  components: { Main,},
    name: 'Home',
  }
</script>
